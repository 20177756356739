import React from "react";
import pageImage from "images/HomepageLogo.png";
import {
  FullBackground,
  Layout,
  SEO,
  WhyCloudLabsAcademiaTables,
  WhyCloudLabsEfficiencyNav,
  WhyCloudLabsNav,
} from "components";
import { graphql } from "gatsby";

const WhyCloudLabsAcademiaPage = ({ data, location }) => {
  const title = "Publish Faster & Cheaper with a Cloud Lab";
  const description = "Go from idea to publication faster and cheaper";
  return (
    <FullBackground gradient>
      <WhyCloudLabsNav section={location && location.pathname} />
      <Layout clearNav clearFooter location={location}>
        <SEO
          image={pageImage}
          title={title}
          uri={location.pathname}
          description={description}
        />
        <WhyCloudLabsEfficiencyNav
          data={data.inPageNav}
          tab={location && location.pathname}
          subsection={location && location.pathname}
          kind={"academia"}
        >
          <WhyCloudLabsAcademiaTables data={data.inTable.standard} />
        </WhyCloudLabsEfficiencyNav>
      </Layout>
    </FullBackground>
  );
};

export default WhyCloudLabsAcademiaPage;

export const query = graphql`
  query WhyCloudLabsAcademiaPage {
    inPageNav: whyCloudLabsJson(tab: { eq: "academia" }) {
      tabRoutes
      useCapacityRow
      nextButton {
        text
        route
      }
    }
    inTable: whyCloudLabsJson(tab: { eq: "academia" }) {
      standard {
        content {
          imageData {
            title
            src
            alt
          }
          tableData {
            col1
            col2
            col3
          }
        }
      }
    }
  }
`;
